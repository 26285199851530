import React, { useState, useEffect } from 'react'
import Table from 'react-bootstrap/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import DataTable from 'datatables.net-dt';
import { Button } from '@mui/material';
import { Guide } from '../modals/Guide';
import routes from '../../helpers/constants';
import useValidateSession from '../../helpers/validateSession';


export const OrdersCreated = () => {
  useValidateSession();

  const userMeli = JSON.parse(localStorage.getItem('userMeLi'));
  const [orders99m, setOrders99m] = useState();
  const [showModalPDF, setShowModalPDF] = useState(false);
  const [counter, setCounter] = useState();

  const getOrdersCreated99 = async () => {
    const data = await fetch(routes.backOrders99, {
        headers: {
          "user": userMeli.user,
        },
        method: 'GET',
    })

    return data.json();
  }

  const openModalGuide = (counter99) => {
    setCounter(counter99)
    setShowModalPDF(true)
  }

  useEffect(() => {
    async function getOrdersCreated() {
      const response = await getOrdersCreated99()
      setOrders99m(response)
    }

    getOrdersCreated();

  }, []);


  if (orders99m === undefined) {
    return <><div className="justify-content-md-center medio"><Spinner animation="border" variant="success" /></div></>
  }
  
  const rows = (orders99m).map(order => {
    const date = new Date(order.createdAt)
    const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    const year = date.getFullYear()
    const month = months[date.getMonth()];
    const day = date.getDate();

    const countryUrlML = (country) => {
      switch (country) {
        case 'MEX':
            return 'mx';
        case 'CHL':
            return 'cl';
        case 'COL':
            return 'co';
        case 'PER':
            return 'pe';
        default:
            return country;
    }
    }

    return (
      <tr key={order._id}>
        <td><a href={`https://www.mercadolibre.com.${countryUrlML(userMeli.country)}/ventas/${order.storeOrderId}/detalle`} target='_blank' rel='noreferrer'>{order.storeOrderId}</a></td>
        <td><a href={`https://tracking.99minutos.com/search/${order.counter}`} target='_blank' rel='noreferrer'>{order.counter}</a></td>
        <td>{`${day}/${month}/${year}`}</td>
        <td>
          <OverlayTrigger
            key={`top-${order._id}`}
            placement={'top'}
            overlay={
              <Tooltip id={`tooltip-top`}>
                Ver segumiento
              </Tooltip>
            }
          >
            <Button variant="contained" onClick={() => window.open(`https://tracking.99minutos.com/search/${order.counter}`)}>
              <i className="fas fa-route"></i>
            </Button>
          </OverlayTrigger>
          {'   '}
          <OverlayTrigger
            key={`bottom-${order._id}`}
            placement={'top'}
            overlay={
              <Tooltip id={`tooltip-top`}>
                Descargar guía 99minutos
              </Tooltip>
            }
          >
            <Button variant="contained" onClick={() => openModalGuide(order.counter)}>
              <i className="fas fa-file-download"></i>
            </Button>
          </OverlayTrigger>
        </td>
      </tr>
    )
  })

  new DataTable('#myTable');
  // new DataTable('#myTable',{
  //   language: {
  //     url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-MX.json'
  //   }
  // });

  return <>
    <div>
      <Guide setShowModalPDF={setShowModalPDF} showModalPDF={showModalPDF} counter={counter}></Guide>
      <Card.Title>Seguimiento a ordenes.</Card.Title>
      <Card.Text>
        Da seguimiento a las ordenes generadas con 99 minutos
      </Card.Text>
        <Table id="myTable" className="table table-striped">
          <thead>
              <tr>
                  <th># Venta MeLi</th>
                  <th>Guía 99minutos</th>
                  <th>Fecha de creación</th>
                  <th>Acciones</th>
              </tr>
          </thead>
          <tbody>
              {rows}
          </tbody>
        </Table>
    </div>
  </>;
};