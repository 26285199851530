import React, { useState } from 'react';
import { Button } from '@mui/material';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import routes from '../../helpers/constants';

export const Guide = ({setShowModalPDF,showModalPDF, counter}) => {

    const userMeli = JSON.parse(localStorage.getItem('userMeLi'));

    const [TypeGuide, setTypeGuide] = useState('letter');
    const handleClose = () => setShowModalPDF(false);

    const downloadPDF = async () => {

        const headers = {
            "user": userMeli.user,
            'Content-Type': 'application/json'
        }
        const payload = {
            'guides':[
                {identifier: counter, size: TypeGuide}
            ]
        }
        const response = await fetch(routes.documentsGuides,{
            method:'POST',
            headers:headers,
            body: JSON.stringify(payload)
        })

        let result = await response.json();

        if(result.code === 200){
            const base64Pdf = result.data[0].pdf;

            const linkSource = `data:application/pdf;base64,${base64Pdf}`;
            const downloadLink = document.createElement("a");
            const fileName = "guias-99minutos.pdf";

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();

            setShowModalPDF(false);
        }
    }

  return (
    <>
      <Modal
        show={showModalPDF}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Descargar guía</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Selecciona el tamaño.
            <br></br>
            <Form.Select onChange={(e) => setTypeGuide(e.target.value)} defaultValue={'letter'}>
                <option value="zebra">Zebra</option>
                <option value="letter">Carta</option>
                <option value="small">Pequeño</option>
            </Form.Select>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="outlined" onClick={handleClose}>
                Cancelar
            </Button>
            <Button variant="contained" onClick={downloadPDF}>Descargar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}