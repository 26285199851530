// const frontBaseUrl  = process.env.REACT_APP_FRONT_URL
const backBaseUrl   = process.env.REACT_APP_BACK_URL
export const mlBaseUrl     = "https://api.mercadolibre.com"

const routes = {
    /* Front */
    // meliLogin:          `${frontBaseUrl}/meli/login`,
    // meliDashboard:      `${frontBaseUrl}/meli/dashboard`,
    // meliOrders:         `${frontBaseUrl}/meli/orders`,
    // meliOrdersCreated:  `${frontBaseUrl}/meli/orders-created`,

    /* Backend */
    // Meli
    backMeliLogin:      `${backBaseUrl}/auth/meli/login`,
    backMeliOrders:     `${backBaseUrl}/meli/orders`,
    backOrders99:       `${backBaseUrl}/meli/99minutos/orders`,
    // 99
    shippingRates:      `${backBaseUrl}/99minutos/shipping-rates`,
    createOrder:        `${backBaseUrl}/99minutos/create-order`,
    documentsGuides:    `${backBaseUrl}/99minutos/documents-guides`,
    createOrders:       `${backBaseUrl}/99minutos/create-orders`,
    // Config
    getConfig:          `${backBaseUrl}/config/get-config`,
    saveConfig:         `${backBaseUrl}/config/save-config`,
    getSchedule:        `${backBaseUrl}/config/get-shipping-schedule`,
    getSites:           `${backBaseUrl}/config/get-sites-config`,

};

export const frontRoutes = {
    login: '/meli/login',
    dashboard: '/meli/dashboard',
}


export default routes;
