// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;


import './App.css';
import { Navigate,Routes, Route } from 'react-router-dom';
// import Home from './pages/Home';
import Login from './pages/Auth/Login';
import { OrderML } from './pages/MercadoLibre/Orders/Order';
import { OrdersCreated } from './pages/MercadoLibre/Orders/OrdersCreated';
import { Dashboard } from './pages/MercadoLibre/Dashboard';

function App(){
  // validateSession();
  // const userMeli = JSON.parse(localStorage.getItem('userMeLi'));
  // if (userMeli === null) {
  //   return window.location.href = routes.meliLogin;
  // }

  return (
    <div>
        <Routes>
          {/* <Route path="/" element={<Home />}></Route> */}
          <Route path="/" element={<Navigate to="/meli/dashboard" />}></Route>
          <Route path="/meli/dashboard" element={<Dashboard />}></Route>
          <Route path="/meli/login" element={<Login />}></Route>
          <Route path="/meli/orders" element={<OrderML />}></Route>
          <Route path="/meli/orders-created" element={<OrdersCreated />}></Route>
        </Routes>
    </div>
  )
}

export default App;