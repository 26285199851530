// import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

import React, { useState } from 'react'

import { NavBar } from './layouts/NavBar';
import { OrderML } from './Orders/Order';
import { OrdersCreated } from './Orders/OrdersCreated';
import useValidateSession from '../helpers/validateSession';
import { Configuration } from './Configuration';

export const Dashboard = () => {
    useValidateSession();
    const [navMenu, setNavMenu] = useState('#pedidos-MeLi')

    const renderComponent = () => {
        switch(navMenu){
            case '#pedidos-MeLi': 
                return <OrderML/>;
            case '#ordenes-generadas': 
                return <OrdersCreated/>;
            case '#configuracion': 
                return <Configuration/>;
            default:
                return <OrderML/>;
        }
    }

    return (
        <>
            <NavBar></NavBar>
            <br></br>
            <Container>
                <Row className="justify-content-md-center">
                    <div>
                        <Card>
                            <Card.Header>
                                <Nav variant="pills" defaultActiveKey="#pedidos-MeLi" onSelect={(selectedKey) => setNavMenu(selectedKey)}>
                                    <Nav.Item>
                                        <Nav.Link href="#pedidos-MeLi">Pedidos MeLi</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link href="#ordenes-generadas">Ordenes generadas</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link href="#configuracion">Configuración</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Card.Header>
                            <Card.Body>
                                {renderComponent()}                        
                            </Card.Body>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}