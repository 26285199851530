import React, { useState, useEffect } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Card from 'react-bootstrap/Card';
import useValidateSession from '../helpers/validateSession';
import { Form } from 'react-bootstrap';
import HelpIcon from '@mui/icons-material/Help';
// import InfoIcon from '@mui/icons-material/Info';
import routes from '../helpers/constants';
import { Button } from '@mui/material';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Chip } from '@mui/material';

export const Configuration = () => {
  useValidateSession();
  const userMeli = JSON.parse(localStorage.getItem('userMeLi'));
  const [autoCreateOrder, setAutoCreateOrder] = useState(false);
  const [disableCheck, setCheckDisaableCalendar] = useState(true);
  const [ignoreCalendar, setIgnoreCalendar] = useState(false);

  const [scheduleML, setScheduleML] = useState({});
  const [sitesML, setSitesML] = useState({});

  const MySwal = withReactContent(Swal);


  const getConfigByUserId = async () => {
    const data = await fetch(`${routes.getConfig}`, {
      headers: {
        user: userMeli.user,
      },
      method: 'GET',
    });

    return data.json();
  };

  const getScheduleML = async () => {
    const data = await fetch(`${routes.getSchedule}`, {
      headers: {
        user: userMeli.user,
      },
      method: 'GET',
    });

    return data.json();
  };

  const getSitesML = async () => {
    const data = await fetch(`${routes.getSites}/${userMeli.country}`, {
      headers: {
        user: userMeli.user,
      },
      method: 'POST',
    });

    return data.json();
  };

  useEffect(() => {
    async function getConfig() {
      const response = await getConfigByUserId();
      if(response.data){
        setAutoCreateOrder(response.data.autoCreateOrder);
        setCheckDisaableCalendar(!response.data.autoCreateOrder)
        setIgnoreCalendar(response.data.ignoreCalendar)
      }
    }
    async function getSchedule() {
      const response = await getScheduleML();
      if(response.data){
        setScheduleML(response.data);
      }
    }
    async function getSites() {
      const response = await getSitesML();
      if(response.data){
        setSitesML(response.data);
      }
    }
    getConfig();
    getSchedule();
    getSites();
  }, []);

  const setAutoCreateOrderFunction = (event) => {
    setAutoCreateOrder(event.target.checked)
    setCheckDisaableCalendar(!event.target.checked)

    if(!event.target.checked){
      setIgnoreCalendar(event.target.checked)
    }
  }

  const setIgnoreCalendarFunction = (event) => {
    setIgnoreCalendar(event.target.checked)
  }

  const saveConfig = async () => {
    let headers = {
      "user": userMeli.user,
      'Content-Type': 'application/json'
    }
    const response = await fetch(routes.saveConfig, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        "autoCreateOrder": autoCreateOrder,
        "ignoreCalendar":ignoreCalendar
      })
    })

    if (response.status === 200) {
      MySwal.fire({
        icon: 'success',
        title: '¡Hecho!',
        text: `Se guardaron tus cambios.`,
        confirmButtonColor: '#85C440',
        // footer: '<a href="">Why do I have this issue?</a>'
      })
    } else {
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        text: `Hubo un error intentando guardar tus cambios.`,
        confirmButtonColor: '#85C440',
        // footer: '<a href="">Why do I have this issue?</a>'
      })
    }
  };

  const autoOrdenIcon = (
    <>
      <OverlayTrigger
        key={'top'}
        placement={'top'}
        overlay={
          <Tooltip id={`tooltip-top`}>
            Cada vez que tengas una nueva venta, se generará automáticamente una guía.
          </Tooltip>
        }
      >
        <HelpIcon fontSize="small" />
      </OverlayTrigger>
    </>
  );
  
  const ignoreCalendarIcon = (
    <>
      <OverlayTrigger
        key={'top'}
        placement={'top'}
        overlay={
          <Tooltip id={`tooltip-top`}>
            Las guías se crearán ignorando el horario de corte que tienes configurado en MercadoLibre.
          </Tooltip>
        }
      >
        <HelpIcon fontSize="small" />
      </OverlayTrigger>
    </>
  );

  return (
    <div>
      <div>
        <h5>Horario de Corte</h5>
        <p>Día y hora para envíos FLEX. Puedes configurar tu horario corte en "Preferencias de venta".</p>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Lunes</th>
              <th>Martes</th>
              <th>Miércoles</th>
              <th>Jueves</th>
              <th>Viernes</th>
              <th>Sábado</th>
              <th>Domingo</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {Object.keys(scheduleML).length > 0 ? Object.keys(scheduleML.schedule).map((day) => (
                <td key={day}>{ scheduleML.schedule[day]?.work ? scheduleML.schedule[day]?.detail[0]?.cutoff : "Sin servicio" }</td>
              )) : <td></td>}
            </tr>
          </tbody>
        </table>
      </div>
      <hr></hr>
      <div>
        <h5>Dirección de Recolección</h5>
        <p>Aquí los veloces irán a recolectar tus paquetes. Puedes configurar tu dirección de recolección en tu perfíl en la sección "Direcciones".</p>
        <p><Chip label={Object.keys(sitesML).length > 0 ? sitesML.configuration.address.address_line +" "+sitesML.configuration.address.city.name+" "+sitesML.configuration.address.zip_code : ""} variant="mora" /></p>
      </div>
      <hr></hr>
      <Card.Title>Configuración extra</Card.Title>
      <Card.Text>
      </Card.Text>
      <Form>
        <Form.Check
          type={'checkbox'}
          id={`default-checkbox`}
          label={<>Creación automática de guía {autoOrdenIcon}</>}
          onChange={setAutoCreateOrderFunction} // Agrega el manejador onChange para actualizar el estado
          checked={autoCreateOrder}
        />
        <Form.Check
          type={'checkbox'}
          id={`default-checkbox-2`}
          label={<>Ignorar horario de corte {ignoreCalendarIcon}</>}
          onChange={setIgnoreCalendarFunction} // Agrega el manejador onChange para actualizar el estado
          checked={ignoreCalendar}
          disabled={disableCheck}
        />
        <hr></hr>
        <Button variant="contained" onClick={() => saveConfig()}>
          Guardar cambios
        </Button>
      </Form>
    </div>
  );
};
