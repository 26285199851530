import React, { useState, useEffect } from 'react'
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
// import DataTable from 'datatables.net-dt';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import routes from '../../helpers/constants';
import useValidateSession from '../../helpers/validateSession';
import { Guide } from '../modals/Guide';

import { Button } from '@mui/material';
import { Chip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

export const OrderML = () => {
  useValidateSession();

  const userMeli = JSON.parse(localStorage.getItem('userMeLi'));
  const [orders, setOrders] = useState();
  const [isPackage, setPackages] = useState(false)
  const [orders99m, setOrders99m] = useState();
  const [showModalPDF, setShowModalPDF] = useState(false);
  const [disableOAButton, setDisableOAButton] = useState(false);

  const [counter, setCounter] = useState();
  const MySwal = withReactContent(Swal);

  const fecha = new Date();
  const año = fecha.getFullYear();
  const mes = String(fecha.getMonth() + 1).padStart(2, "0");
  const día = String(fecha.getDate()).padStart(2, "0");
  const formatoDeseado = `${año}-${mes}-${día}`;
  const [fechaFrom, setFechaFrom] = useState(formatoDeseado);
  const [fechaTo, setFechaTo] = useState(formatoDeseado);

  const handleChangeFechaFrom = (event) => {
    const fechaSeleccionada = event.target.value;
    setFechaFrom(fechaSeleccionada);
  };
  const handleChangeFechaTo = (event) => {
    const fechaSeleccionada = event.target.value;
    setFechaTo(fechaSeleccionada);
  };
  const getOrdersMeliByDates = async () => {
    const responseOrdersMeli = await getOrdersMeli()
    setPackages(false)
    setOrders(responseOrdersMeli)
  }

  const [idOrderMeli,setIdOrderMeli] = useState('');

  const handleChangeOrderMeli = (event) => {
    setPackages(false)
    const orden = event.target.value;
    setIdOrderMeli(orden);
  };

  const getOrderMeliById = async () => {
    const responseOrderMeli = await getOrderMeli()
    
    if (responseOrderMeli.name === "AxiosError") {
      setOrders({
        "results": []
      })  
    }else{
      setPackages(responseOrderMeli.isPackage)
      setOrders({
        "results": responseOrderMeli.results
      })
    }
  }

  const clearFilters = async () => {
    setFechaFrom('')
    setFechaTo('')
    setIdOrderMeli('');
    setOrders({
      "results": []
    })
    setPackages(false)
  }

  const getOrdersMeli = async () => {
    const data = await fetch(routes.backMeliOrders, {
      headers: {
        "user": userMeli.user,
        "fromml": `${fechaFrom}T00:00:00.000${check_time_zone(userMeli.country)}`,
        "toml": `${fechaTo}T23:59:59.000${check_time_zone(userMeli.country)}`
      },
      method: 'GET',
    })

    return data.json();
  }

  const getOrdersCreated99 = async () => {
    const data = await fetch(routes.backOrders99, {
      headers: {
        "user": userMeli.user,
      },
      method: 'GET',
    })

    return data.json();
  }

  const getOrderMeli = async () => {
    const data = await fetch(`${routes.backMeliOrders}/${idOrderMeli}`, {
      headers: {
        "user": userMeli.user,
      },
      method: 'GET',
    })

    return data.json();
  }

  const check_delivery_type = (country) => {
    switch (country) {
      case 'MEX':
        return '99minutos';
      case 'CHL':
        return 'SameDay';
      case 'COL':
        return 'SameDay';
      case 'PER':
        return 'SameDay';
      default:
        return country;
    }
  }

  const check_time_zone = (country) => {
    switch (country) {
      case 'MEX':
        return '-06:00';
      case 'CHL':
        return '-04:00';
      case 'COL':
        return '-05:00';
      case 'PER':
        return '-05:00';
      default:
        return '-00:00';
    }
  }

  const todayParams = {
    "from": `${año}-${mes}-${día}T00:00:00.000${check_time_zone(userMeli.country)}`,
    "to": `${año}-${mes}-${día}T23:59:59.000${check_time_zone(userMeli.country)}`
  }

  const createOrders = async () => {
    setDisableOAButton(true)
    let headers = {
      "user": userMeli.user,
      'Content-Type': 'application/json'
    }
    const response = await fetch(routes.createOrders, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(todayParams)
    })

    let result = await response.json();

    if (result.code === 200) {
      const resultados = result.data.map((element) => {
        if (element.code !== 200) {
          setDisableOAButton(false)
          return {
            order: element.data.orderId,
            message: element.data.message
          }
        } else {
          setDisableOAButton(false)
          return {}
        }
      });

      if (resultados.length > 0) {
        let lista = '';

        resultados.forEach(resultado => {
          if (resultado.hasOwnProperty('order') && resultado.hasOwnProperty('message')) {
            lista += `<li>Orden: ${resultado.order}, Razón: ${resultado.message}</li>`;
          }
        });
        
        MySwal.fire({
          icon: 'info',
          title: 'Estas ordenes no fueron generadas:',
          html: `<ul>${lista}</ul>`,
          confirmButtonColor: '#85C440',
        })
      }else{
        if(result.message === "No hay ordenes."){
          MySwal.fire({
            icon: 'info',
            title: 'Sin ordenes',
            text: `No tiene órdenes el día de hoy`,
            confirmButtonColor: '#85C440',
          })
        }else{
          MySwal.fire({
            icon: 'success',
            title: '¡Hecho!',
            text: `Se han generado guías de las órdenes del día`,
            confirmButtonColor: '#85C440',
          })
        }
        
      }
    }

    setDisableOAButton(false)
    setPackages(false)

    const responseOrdersMeli = await getOrdersMeli()
    setOrders(responseOrdersMeli)

    const responseOrdersCreated99 = await getOrdersCreated99()
    setOrders99m(responseOrdersCreated99)
  }

  useEffect(() => {
    async function getOrders() {
      const response = await getOrdersMeli()
      setOrders(response)
    }
    async function getOrdersCreated() {
      const response = await getOrdersCreated99()
      setOrders99m(response)
    }
    getOrders()
    getOrdersCreated();
  }, []);

  if (orders === undefined) {
    return <><div className="justify-content-md-center medio"><Spinner animation="border" variant="success" /></div></>
  }

  const openModalGuide = (orderId) => {
    for (let index = 0; index < orders99m.length; index++) {
      if (Number(orders99m[index].storeOrderId) === orderId) {
        setCounter(orders99m[index].counter)
        break
      }
    }
    setShowModalPDF(true)
  }

  if (orders.results === undefined) {
    orders.results = []
  }

  const rows = (orders.results).map(order => {
    const {
      id,             // id de MeLi
      status,         // Estatus de la orden (paid, cancelled, ...)
      date_created,   // Fecha creacion de la orden
      coverage,       // Tiene cobertura 99m (true/false)
      actions,        // acciones para la orden
      status_actions, // estatus para las acciones ( 0. Sin acciones, 1. Orden ya generada, 2. Validar cobertura, 3. Crear Orden/Sin cobertura )
      total_price,    // precio de la tarifa 99M / SMD
    } = order;

    if (order.status !== 'cancelled') {
      order.status_actions = 2
    }

    if (typeof order.coverage != 'undefined' && (order.coverage === true || order.coverage === false)) {
      order.status_actions = 3
    }

    if (orders99m?.length > 0) {
      for (let index = 0; index < orders99m.length; index++) {
        if (Number(orders99m[index].storeOrderId) === order.id) {
          order.status_actions = 1
          break
        } else if (order.tags.includes("delivered")) {
          order.status_actions = 4
        }
      }
    } else {
      if (order.tags.includes("delivered")) {
        order.status_actions = 4
      }
    }

    const date = new Date(order.date_created)
    const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    const year = date.getFullYear()
    const month = months[date.getMonth()];
    const day = date.getDate();

    let orderStatus = '';
    switch (order.status) {
      case 'paid':
        orderStatus = 'Pedido pagado'
        if (order.tags.includes("delivered")) {
          orderStatus = 'Pedido entregado'
        }
        break;
      case 'cancelled':
        orderStatus = 'Pedido cancelado'
        break;
      case 'confirmed':
        orderStatus = 'Pedido confirmado'
        break;
      default:
        break;
    }

    const printActions = (status) => {
      switch (status) {
        case 0:
          return ''
        case 1:
          return <>
            <Chip label="Orden creada con 99 minutos" variant="limon" />
            {'   '}
            <OverlayTrigger
              key={'top'}
              placement={'top'}
              overlay={
                <Tooltip id={`tooltip-top`}>
                  Descargar guía 99minutos
                </Tooltip>
              }
            >
              <Button variant="contained" onClick={() => openModalGuide(order.id)}>
                <i className="fas fa-file-download"></i>
              </Button>
            </OverlayTrigger>
          </>
        case 2:
          return <>
            <OverlayTrigger
              key={'top'}
              placement={'top'}
              overlay={
                <Tooltip id={`tooltip-top`}>
                  Verificar cobertura
                </Tooltip>
              }
            >
              <Button variant="contained" onClick={() => checkCoverage(order.id)}>
                <i className="fas fa-search"></i>
              </Button>
            </OverlayTrigger>
          </>
        case 3:
          if (order.coverage) {
            return <><OverlayTrigger
              key={'top'}
              placement={'top'}
              overlay={
                <Tooltip id={`tooltip-top`}>
                  <strong>Costo total de la orden</strong>: ${order.total_price}
                </Tooltip>
              }
            >
              <Button variant="contained" onClick={() => createOrder(order.id)}>Crear orden</Button>
            </OverlayTrigger>
            </>
          } else {
            return <Chip label="Sin cobertura" variant="mora" />
          }
        case 4:
          return <Chip label="Orden creada con otro proveedor" variant="melon" />
        default:
          break;
      }
    }

    const checkCoverage = async (orderId) => {
      let headers = {
        "user": userMeli.user,
        'Content-Type': 'application/json'
      }
      const response = await fetch(routes.shippingRates, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          "ordermeli": orderId
        })
      })

      if (response.status !== 200) {
        setOrders({
          ...orders, data: (orders.results).map(order => {
            if (order.id === orderId) {
              order.coverage = false
              order.status_actions = 3
            }
            return order
          })
        })

        return
      }

      let result = await response.json();

      const haveMethod = (result.data).some((method) => {
        if (method.deliveryType === check_delivery_type(userMeli.country)) {
          order.total_price = method.prices.total
          return method.deliveryType === check_delivery_type(userMeli.country)
        }
      })

      setOrders({
        ...orders, data: (orders.results).map(order => {
          if (order.id === orderId) {
            order.coverage = haveMethod
            order.status_actions = 3
          }
          return order
        })
      })

      if (haveMethod) {
        Swal.fire({
          title: `¿Desea generar la orden?`,
          text: `Costo total para generar la orden ${check_delivery_type(userMeli.country)} es de: $${order.total_price}`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#85C440',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Crear orden',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (result.isConfirmed) {
            createOrder(orderId)
          }
        })
      }
    }

    const createOrder = async (orderId) => {

      let headers = {
        "user": userMeli.user,
        'Content-Type': 'application/json'
      }
      const response = await fetch(routes.createOrder, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          "ordermeli": orderId
        })
      })

      let result = await response.json();

      if (result.code === 400) {
        MySwal.fire({
          icon: 'error',
          title: 'Error',
          text: `${result.message.message}`,
          confirmButtonColor: '#85C440',
          // footer: '<a href="">Why do I have this issue?</a>'
        })
      } else if (result.code === 412) {
        MySwal.fire({
          icon: 'warning',
          title: 'Alerta',
          text: `${result.message.message}`,
          confirmButtonColor: '#85C440',
          // footer: '<a href="">Why do I have this issue?</a>'
        })
      } else if (result.code === 200) {
        MySwal.fire({
          icon: 'success',
          title: '¡Hecho!',
          text: `Se a generado la orden con 99 minutos`,
          confirmButtonColor: '#85C440',
          // footer: '<a href="">Why do I have this issue?</a>'
        })
      }

      setPackages(false)

      const responseOrdersMeli = await getOrdersMeli()
      setOrders(responseOrdersMeli)

      const responseOrdersCreated99 = await getOrdersCreated99()
      setOrders99m(responseOrdersCreated99)
    }

    const countryUrlML = (country) => {
      switch (country) {
        case 'MEX':
          return 'mx';
        case 'CHL':
          return 'cl';
        case 'COL':
          return 'co';
        case 'PER':
          return 'pe';
        default:
          return country;
      }
    }

    return (
      <tr key={order.id}>
        <td><a href={`https://www.mercadolibre.com.${countryUrlML(userMeli.country)}/ventas/${order.id}/detalle`} target='_blank' rel='noreferrer'>{order.id}</a></td>
        <td>{`${day}/${month}/${year}`}</td>
        <td>{orderStatus}</td>
        <td>{printActions(order.status_actions)}{order.actions}</td>
      </tr>
    )
  })

  // new DataTable('#myTable');

  // new DataTable('#myTable',{
  //   language: {
  //     url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-MX.json'
  //   }
  // });

  return <>
    <div>
      <Guide setShowModalPDF={setShowModalPDF} showModalPDF={showModalPDF} counter={counter}></Guide>
      <Card.Title>Lista de Pedidos MeLi</Card.Title>
      <hr></hr>
      <Card.Text>
        Filtra tus órdenes por fecha de creación o por # Venta MeLi.
      </Card.Text>
      <Form.Group className="row g-3">
        {/* POR FECHAS */}
        <div className='col-2'>
          <Form.Label>Fecha inicio:</Form.Label>
          <Form.Control placeholder="Fecha Inicio" type='date' value={fechaFrom} onChange={handleChangeFechaFrom} />
        </div>
        <div className='col-2'>
          <Form.Label>Fecha fin:</Form.Label>
          <Form.Control placeholder="Fecha Fin" type='date' value={fechaTo} onChange={handleChangeFechaTo} />
        </div>
        <div className='col-2' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button variant="contained" type="submit" onClick={getOrdersMeliByDates}>
            Buscar órdenes
          </Button>
        </div>

        {/* POR ID DE ORDEN */}
        <div className='col-2'>
          <Form.Label># Venta MeLi:</Form.Label>
          <Form.Control placeholder="# venta" value={idOrderMeli} onChange={handleChangeOrderMeli} />
        </div>
        <div className='col-2' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button variant="contained" type="submit" onClick={getOrderMeliById}>
            Buscar orden
          </Button>
        </div>
        {/* BORRAR FILTROS */}
        <div className='col-2' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button variant="outlined" type="submit" onClick={clearFilters}>
            Borrar filtros
          </Button>
        </div>
      </Form.Group>
      <hr></hr>
      <Card.Text>
        Busca cobertura "{check_delivery_type(userMeli.country)}" y genera guía para cada pedido que desees.
        {/* <span style={{ float:"right" }}>asdasdasd</span> */}
        
        {isPackage? <><br></br><Chip label={`"${idOrderMeli}" es un ID de paquete, que contiene una o varias ordenes`} variant="mora" /></>:<></>}
        <OverlayTrigger
          key={'top'}
          placement={'top'}
          overlay={
            <Tooltip id={`tooltip-top`}>
              <InfoIcon fontSize="small" /> Al tener demasiadas ventas, con esta opción te ahorras los pasos de verificar cobertura y generar guía venta tras venta.
            </Tooltip>
          }
        >
          <Button variant="outlined" style={{ float: "right" }} onClick={createOrders} disabled={disableOAButton}>
            Generar guías de las ventas del día ({día}/{mes}/{año})
          </Button>
        </OverlayTrigger>
      </Card.Text>
      <Table id="myTable" className="table table-striped">
        <thead>
          <tr>
            <th># Venta MeLi</th>
            <th>Fecha de pedido</th>
            <th>Estatus de pedido</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
    </div>
  </>;
};